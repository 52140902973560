@import "../../../data/styles.css";

.footer {
	display: flex;
	padding-top: 25px;
	margin-top: 50px;
	padding-bottom: 25px;
	align-items: center;
    justify-content: space-between;
}

.footer-links,
.footer-credits {
	flex-basis: auto;
}

.footer-links {
	margin-left: 0;
}

.footer-nav-link-list {
	display: flex;
	list-style: none;
	justify-content: flex-start;
	align-items: center;
}

.footer-nav-link-item {
	font-weight: bold;
	font-size: 80%;
    margin-right: 20px;
}

.footer-nav-link-list a {
	text-decoration: none;
	color: var(--secondary-color);
}

.footer-nav-link-list a:hover {
	color: var(--link-color);
}

.footer-credits-text {
	justify-content: flex-end;
	color: var(--tertiary-color);
	font-size: 14px;
	text-align: right;
}

@media (max-width: 600px) {
	.footer {
		flex-direction: column;
		height: 85px;
	}

	.footer-links {
		display: flex;
		width: 100%;
		justify-content: center;
	}

	.footer-nav-link-list {
		width: 100%;
	}
}
